import React, { useContext, useEffect, useState } from "react";
import {
  Accordion, Button, Dialog, Drawer, Heading, Layout, Select, Switch, Text, TextField,
} from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";

import styles from "./ProductGroupModal.module.scss";
import { brands, categories } from "../../../data/productSpecs";
import PictureLoader from "../../common/PictureLoader";
import { getProductDescription, getProductMarketingMaterial } from "../../../tools/ChatGPTApi";
import { useToastContext } from "../../../contexts/ToastContext";

const countries = ["France", "Germany", "Spain", "United Kingdom", "Other"];

const initialProductGroup = {
  name: "",
  description: "",
  brand: "",
  category: "",
  img_url: "",
  available_countries: countries,
  marketing_blocks: [],
  technical_specs: [],
};

const ProductGroupModal = (props) => {
  const {
    product_group: productGroupFromProps, open, onCloseModal, onSaveModal, fetchProductGroups,
  } = props;

  const { user: adminUser } = useContext(UserContext);
  const { addToast } = useToastContext();

  const brands_list = brands.map((b) => b.slug);

  const [productGroup, setProductGroup] = useState(initialProductGroup);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [files, setFiles] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isAiLoading, setIsAiLoading] = useState(false);
  const [expandMarketing, setExpandMarketing] = useState(false);
  const [expandTechSpecs, setExpandTechSpecs] = useState(false);
  const [expandSpecs, setExpandSpecs] = useState(false);
  const [expandAvailability, setExpandAvailability] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "available_countries") {
      const optionsSelected = [...productGroup.available_countries];
      const optionIndex = optionsSelected.findIndex((v) => v === value);

      if (optionIndex >= 0) {
        optionsSelected.splice(optionIndex, 1);
      } else {
        optionsSelected.push(value);
      }

      setProductGroup({ ...productGroup, [name]: optionsSelected });
    } else {
      setProductGroup((prevInfo) => ({ ...prevInfo, [name]: value }));
    }
  };

  const handleMultiblockChange = (blockName, blockKey, blockIndex, blockValue) => {
    const updatedBlocks = Array.isArray(productGroup[blockName]) ? [...productGroup[blockName]] : [];
    const newBlock = { ...updatedBlocks[blockIndex] };

    newBlock[blockKey] = blockValue;
    updatedBlocks[blockIndex] = newBlock;

    setProductGroup({ ...productGroup, [blockName]: updatedBlocks });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (Object.keys(files).length > 0) {
        const formData = new FormData();

        for (const key of Object.keys(files)) {
          formData.append(key, files[key]);
        }

        formData.append("name", productGroup.name);
        formData.append("description", productGroup.description);
        formData.append("brand", productGroup.brand);
        formData.append("category", productGroup.category);
        productGroup.marketing_blocks.forEach((block, index) => {
          if (block) formData.append(`marketing_blocks[${index}]`, JSON.stringify(block));
        });
        productGroup.technical_specs.forEach((block, index) => {
          if (block) formData.append(`technical_specs[${index}]`, JSON.stringify(block));
        });
        formData.append("img_url", productGroup.img_url);

        if (productGroup.img_url_2) formData.append("img_url_2", productGroup.img_url_2);

        if (productGroup.img_url_3) formData.append("img_url_3", productGroup.img_url_3);

        if (productGroup.img_url_4) formData.append("img_url_4", productGroup.img_url_4);

        if (productGroup.img_url_5) formData.append("img_url_5", productGroup.img_url_5);

        formData.append("available_countries", `{${productGroup.available_countries.join(",")}}`);

        if (productGroupFromProps) {
          await adminUser.api.modifyProductGroup(productGroupFromProps.id, formData);
        } else {
          await adminUser.api.addProductGroup(formData);
        }
      } else if (productGroupFromProps) {
        await adminUser.api.modifyProductGroup(productGroupFromProps.id, productGroup);
      } else {
        await adminUser.api.addProductGroup(productGroup);
      }
      onSaveModal();
    } catch (err) {
      addToast(err.response?.data?.message || "Couldn't proceed.");
      console.error(err);
      setIsLoading(false);
    }
  };

  const deleteProductGroup = async () => {
    try {
      // delete also the associated products (specified in the delete confirmation message)
      await adminUser.api.deleteProductGroup(productGroup.id);
      setConfirmModalOpen(false);
      onCloseModal();
      await fetchProductGroups();
    } catch (err) {
      console.error(err);
    }
  };

  const cancelDelete = () => {
    setConfirmModalOpen(false);
    onCloseModal();
  };

  const onImageChange = (field, value) => {
    if (value === null) {
      const newFiles = { ...files };

      delete newFiles[field];
      setFiles(newFiles);
    } else {
      setFiles({ ...files, [field]: value });
    }
  };

  const onSelectOption = (field, value) => {
    setProductGroup((pg) => ({ ...pg, [field]: value }));
  };

  const getAiProductDescription = async (pg) => {
    setProductGroup((prevInfo) => ({ ...prevInfo, description: "Loading..." }));
    setIsAiLoading("description");
    const description = await getProductDescription(pg);

    setIsAiLoading(false);
    setProductGroup((prevInfo) => ({ ...prevInfo, description }));
  };

  const getAiMarketingBlocks = async (pg) => {
    setIsAiLoading("marketing_blocks");
    const marketing_blocks = await getProductMarketingMaterial(pg);

    setIsAiLoading(false);
    setProductGroup((prevInfo) => ({ ...prevInfo, marketing_blocks }));
  };

  const handleCloseModal = () => {
    setProductGroup(initialProductGroup);
    onCloseModal();
  };

  useEffect(() => {
    if (productGroupFromProps) {
      // Remove every null values from object so it's overide by initialProduct
      const productGroupFromPropsWithoutNull = Object.fromEntries(Object.entries(productGroupFromProps).filter(([, v]) => v != null));

      setProductGroup({ ...initialProductGroup, ...productGroupFromPropsWithoutNull });
    } else {
      setProductGroup(initialProductGroup);
    }
  }, [productGroupFromProps]);

  const brandOptions = [
    { label: "-", value: "" },
    ...brands_list.map((b) => ({ label: b.toUpperCase(), value: b })),
  ];

  const categoryOptions = [
    { label: "-", value: "" },
    ...categories.map((c) => {
      if (c === "SCREEN") {
        return ({ label: "SCREEN & TV", value: c });
      }

      return ({ label: c, value: c });
    }),
  ];

  const ActionComponent = (
    <>
      <Button color="secondary" label="No" onClick={cancelDelete} />
      <Button color="primary" label="Yes" onClick={deleteProductGroup} />
    </>
  );

  const DrawerActions = (
    <>
      <Button
        variant="contained"
        color="primary"
        label="Save"
        loading={isLoading}
        onClick={handleSubmit}
      />

      <Button
        disabled={!productGroupFromProps}
        variant="contained"
        color="error"
        label="Delete"
        onClick={() => setConfirmModalOpen(true)}
      />
    </>
  );

  return (
    <>
      <Drawer
        onClose={handleCloseModal}
        title={!productGroupFromProps ? "Create a Product Group" : "Edit a Product Group"}
        Actions={DrawerActions}
        open={open}
      >
        <Layout direction="column" isScrollable>
          <Layout direction="column" spacing={1}>

            <Accordion title="Characteristics" expanded={expandSpecs} onChange={() => setExpandSpecs(!expandSpecs)}>
              <Layout direction="column" spacing={1}>
                <div className={styles.selectPair}>
                  <Select
                    label="Brand"
                    required
                    value={productGroup.brand || ""}
                    options={brandOptions}
                    onChange={(value) => onSelectOption("brand", value)}
                  />

                  <Select
                    label="Category"
                    required
                    value={productGroup.category}
                    options={categoryOptions}
                    onChange={(value) => onSelectOption("category", value)}
                  />
                </div>

                <TextField
                  label="Name"
                  name="name"
                  value={productGroup.name}
                  onChange={handleChange}
                  placeholder='ex : Microsoft Surface Book 3 13.5""'
                  required
                />

                <TextField
                  label="Description"
                  name="description"
                  value={productGroup.description}
                  onChange={handleChange}
                  fullWidth
                  required
                  multiline
                  rows={10}
                />

                <Button
                  variant="outlined"
                  color="secondary"
                  label="Get AI description"
                  size="small"
                  onClick={() => getAiProductDescription(productGroup)}
                  loading={isAiLoading === "description"}
                />

                <Layout direction="column" spacing={2} alignItems="center">
                  <Heading variant="h5">Main picture</Heading>
                  <PictureLoader
                    image={productGroup.img_url}
                    onChange={(file) => onImageChange("mainImg", file)}
                  />
                  <Heading variant="h5">All images must be at format 792 x 446 px (16:9)</Heading>

                  <Layout direction="row" spacing={2} flexWrap="wrap" justifyContent="center">
                    <PictureLoader
                      small
                      image={productGroup.img_url_2}
                      onChange={(file) => onImageChange("img2", file)}
                      onDelete={() => {
                        onImageChange("img2", null);
                        handleChange({ target: { name: "img_url_2", value: null } });
                      }}
                    />
                    <PictureLoader
                      small
                      image={productGroup.img_url_3}
                      onChange={(file) => onImageChange("img3", file)}
                      onDelete={() => {
                        onImageChange("img3", null);
                        handleChange({ target: { name: "img_url_3", value: null } });
                      }}
                    />
                    <PictureLoader
                      small
                      image={productGroup.img_url_4}
                      onChange={(file) => onImageChange("img4", file)}
                      onDelete={() => {
                        onImageChange("img4", null);
                        handleChange({ target: { name: "img_url_4", value: null } });
                      }}
                    />
                    <PictureLoader
                      small
                      image={productGroup.img_url_5}
                      onChange={(file) => onImageChange("img5", file)}
                      onDelete={() => {
                        onImageChange("img5", null);
                        handleChange({ target: { name: "img_url_5", value: null } });
                      }}
                    />
                  </Layout>
                </Layout>
              </Layout>
            </Accordion>

            <Accordion title="Availabilities" expanded={expandAvailability} onChange={() => setExpandAvailability(!expandAvailability)}>
              <Layout direction="column" spacing={1}>
                <Text variant="body2">Available countries</Text>
                {(countries.map((country) => (
                  <Layout direction="column" spacing={1} key={country}>
                    <Switch
                      label={country}
                      checked={productGroup.available_countries?.includes(country) || false}
                      onChange={() => handleChange({ target: { name: "available_countries", value: country } })}
                    />
                  </Layout>
                )))}
              </Layout>
            </Accordion>

            <Accordion title="Marketing blocks" expanded={expandMarketing} onChange={() => setExpandMarketing(!expandMarketing)}>
              <Layout direction="column" spacing={2}>
                <Layout direction="row" spacing={1}>
                  <Button
                    onClick={() => getAiMarketingBlocks(productGroup)}
                    variant="outlined"
                    color="secondary"
                    label="Get AI marketing content"
                    size="small"
                    loading={isAiLoading === "marketing_blocks"}
                  />
                  <Text variant="body2">Takes ~30s, works 60% of the time</Text>
                </Layout>

                {Array.from({ length: 3 }).map((value, index) => (
                  <Layout direction="column" spacing={1} key={`mkt block n°${index}`}>
                    <TextField
                      key={`${value} mkt title`}
                      label={`Title ${index + 1}`}
                      name={`marketing_blocks-${index}_title`}
                      value={productGroup.marketing_blocks[index]?.title || ""}
                      required
                      onChange={(e) => handleMultiblockChange("marketing_blocks", "title", index, e.target.value)}
                    />

                    <TextField
                      key={`${value} mkt description`}
                      fullWidth
                      multiline
                      rows={4}
                      label={`Text ${index + 1}`}
                      required
                      name={`marketing_blocks-${index}_description`}
                      value={productGroup.marketing_blocks[index]?.description || ""}
                      onChange={(e) => handleMultiblockChange("marketing_blocks", "description", index, e.target.value)}
                    />
                  </Layout>
                ))}
              </Layout>
            </Accordion>

            <Accordion title="Technical specifications" expanded={expandTechSpecs} onChange={() => setExpandTechSpecs(!expandTechSpecs)}>
              <Layout direction="column" spacing={2}>
                {Array.from({ length: 6 }).map((value, index) => (
                  <Layout direction="row" spacing={1} key={`tech spec n°${index}`}>
                    <TextField
                      label={`Title ${index + 1}`}
                      required={index < 3}
                      name={`technical_specs-${index}_title`}
                      value={productGroup.technical_specs[index]?.title || ""}
                      onChange={(e) => handleMultiblockChange("technical_specs", "title", index, e.target.value)}
                    />

                    <TextField
                      label={`Text ${index + 1}`}
                      required={index < 3}
                      name={`technical_specs-${index}_description`}
                      value={productGroup.technical_specs[index]?.description || ""}
                      onChange={(e) => handleMultiblockChange("technical_specs", "description", index, e.target.value)}
                    />
                  </Layout>
                ))}
              </Layout>
            </Accordion>
          </Layout>
        </Layout>
      </Drawer>

      <Dialog
        onClose={() => setConfirmModalOpen(false)}
        title="Delete a Product Group"
        Actions={ActionComponent}
        open={confirmModalOpen}
        setConfirmModalOpen={setConfirmModalOpen}
      >
        This action will delete the selected product group.
        <br />
        All the related products will be deleted too.
        <br />
        Continue ?
      </Dialog>
    </>
  );
};

export default ProductGroupModal;
