import React, { useMemo } from "react";
import {
  Heading, Layout, Select, Text,
} from "@fleet.co/tarmac";

import styles from "../CompanyDiscount.module.scss";
import { formatMoney } from "../../../../helpers/formatHelpers";

const DiscountTableRow = (props) => {
  const {
    handleChange, discount, isEditable, discountInfos, discountCurrency,
  } = props;

  const discountOptions = useMemo(() => {
    const baseDiscountOptions = [
      {
        label: "NO DISCOUNT", offer_type: "NO_DISCOUNT", offer_value: 0,
      },
      {
        label: "1 month offered", offer_type: "MONTH_OFFERED", offer_value: 1,
      },
    ];
    const higherDiscountOptions = [
      {
        label: formatMoney(3, discountCurrency), offer_type: "CURRENCY_DISCOUNT", offer_value: 3,
      },
      {
        label: formatMoney(5, discountCurrency), offer_type: "CURRENCY_DISCOUNT", offer_value: 5,
      },
      {
        label: formatMoney(10, discountCurrency), offer_type: "CURRENCY_DISCOUNT", offer_value: 10,
      },
      {
        label: formatMoney(15, discountCurrency), offer_type: "CURRENCY_DISCOUNT", offer_value: 15,
      },
      {
        label: formatMoney(20, discountCurrency), offer_type: "CURRENCY_DISCOUNT", offer_value: 20,
      },
    ];

    return discountInfos.level === 1 ? baseDiscountOptions : [...baseDiscountOptions, ...higherDiscountOptions];
  }, [discountCurrency, discountInfos.level]);

  const currencySymbol = discountCurrency === "EUR" ? "€" : "£";

  const handleSelectChange = (value) => {
    const chosenDiscount = discountOptions.find((opt) => opt.offer_value === value);

    const formattedDiscount = {
      offer_type: chosenDiscount.offer_type,
      offer_value: chosenDiscount.offer_value,
    };

    handleChange(discountInfos.level, formattedDiscount);
  };

  return (
    <Layout
      direction="row"
      fullWidth
      justifyContent="space-around"
      alignItems="center"
      className={styles.tableRow}
    >
      <Layout direction="column" className={styles.cellRow}>
        <Heading variant="h5">
          Level
          {" "}
          {discountInfos.level}
        </Heading>
        <Text variant="body1">
          {`${discountInfos.min} < ${discountInfos.max
            ? discountInfos.max
            : "+"}${discountInfos.level !== 5 ? currencySymbol : ""}`}
        </Text>
      </Layout>

      <Select
        value={discount?.offer_value || 0}
        onChange={handleSelectChange}
        options={discountOptions.map((opt) => ({ label: opt.label, value: opt.offer_value }))}
        disabled={!isEditable}
      />

      <Layout direction="column" className={styles.cellRow} alignItems="flex-end">
        <Text variant="body1">
          {discount?.updated_at ? new Date(discount.updated_at).toLocaleDateString("fr") : "-"}
        </Text>
      </Layout>
    </Layout>
  );
};

export default DiscountTableRow;
