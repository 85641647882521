import React, { useContext, useEffect, useState } from "react";
import {
  Alert, Button, Heading, Icon, Layout, Link, Radio, RadioGroup, Separator, Text,
  Tooltip,
} from "@fleet.co/tarmac";
import { faFileCirclePlus, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import GenerateFiles from "../../blocks/GenerateFiles";
import UserContext from "../../../tools/UserContext";
import { countryWithInvoicing } from "../../../tools/SpecificityByCountries";

const MutationStatus = {
  IDLE: "IDLE",
  PENDING: "PENDING",
};

const OrderBilling = ({ order, reload }) => {
  const { user: adminUser } = useContext(UserContext);
  const [isAlreadyGenerated, setIsAlreadyGenerated] = useState(false);
  const [error, setError] = useState(null);
  const [mutation, setMutation] = useState({
    changeInsuranceStatus: MutationStatus.IDLE,
    cancelInsurance: MutationStatus.IDLE,
    sendToPennylane: MutationStatus.IDLE,
  });
  const [isEditable, setIsEditable] = useState(false);
  const [insuranceType, setInsuranceType] = useState(order.insurance_type);
  const [insurance, setInsurance] = useState(null);
  const [insurancePrice, setInsurancePrice] = useState(null);
  const [isFleetCareSelectable, setIsFleetCareSelectable] = useState(true);
  const [errorArray, setErrorArray] = useState([]);

  const hasPersonalInsuranceFileAccepted = order.company?.files?.some((file) => file.type === "INSURANCE" && file.status === "ACCEPTED" && file.deleted === false);

  function getMonthsElapsed(startDate) {
    const start = new Date(startDate);
    const now = new Date();

    const yearsDifference = now.getFullYear() - start.getFullYear();
    const monthsDifference = now.getMonth() - start.getMonth();

    return yearsDifference * 12 + monthsDifference;
  }

  const dateConverterToddmmyyyy = (date) => {
    const pad = (s) => (s < 10 ? `0${s}` : s);

    return [pad(date.getDate()), pad(date.getMonth() + 1), date.getFullYear()].join("/");
  };

  const sendToPennylane = async () => {
    setMutation({ ...mutation, sendToPennylane: MutationStatus.PENDING });

    try {
      await adminUser.api.sendInvoice(order.id);
    } catch (err) {
      setError(err.response.data.message);
    } finally {
      setMutation({ ...mutation, sendToPennylane: MutationStatus.IDLE });
    }
  };

  const orderStatusToDisplay = ["DELIVERED", "BDL_SENT", "RECEIVED", "FINALIZED", "CLOSED"];

  const saveStatusChange = async () => {
    setMutation({ ...mutation, changeInsuranceStatus: MutationStatus.PENDING });

    // Only send to backend if :
    const orderChange = {
      insurance_type: insuranceType,
    };

    try {
      await adminUser.api.modifyOrder(order.id, orderChange);
      setIsEditable(false);
      reload();
    } catch (error) {
      setError(`An error occured while saving the insurance type. Error: "${error.response?.data?.message ?? error.message}"`);
    } finally {
      setMutation({ ...mutation, changeInsuranceStatus: MutationStatus.IDLE });
    }
  };

  const cancelEdit = () => {
    setIsEditable(false);
    setInsurancePrice(null);
  };

  const loadInsurance = async () => {
    const insurance = await adminUser.api.getSubscription(order.id);

    setInsurance(insurance.data);
  };

  const handleInsuranceTypeChange = async (event) => {
    setInsuranceType(event.target.value);
    if (event.target.value === "FLEET_INSURANCE" && order.insurance_type !== "FLEET_INSURANCE") {
      const response = await adminUser.api.getFleetInsurancePrice(order.id);

      setInsurancePrice((response.data.totalPrice / 100).toFixed(2));
    }

    if (event.target.value !== "FLEET_INSURANCE") {
      setInsurancePrice(null);
    }
  };

  const cancelInsurance = async () => {
    setMutation({ ...mutation, cancelInsurance: MutationStatus.PENDING });
    try {
      await adminUser.api.cancelInsurance(order.id);
      reload();
    } catch (error) {
      setError("An error occured while cancelling the insurance");
    } finally {
      setMutation({ ...mutation, cancelInsurance: MutationStatus.IDLE });
    }
  };

  useEffect(() => {
    loadInsurance();
  }, [order]);

  useEffect(() => {
    const monthsElapsedSinceOrderReceived = getMonthsElapsed(order.reception_date);

    if (!order.reception_date) {
      return;
    }

    if (monthsElapsedSinceOrderReceived === 0) {
      return;
    }

    setErrorArray([]);

    if (monthsElapsedSinceOrderReceived > 30) {
      setErrorArray((oldArray) => [...oldArray, "The contract is too old ( > 30 months )"]);
      setIsFleetCareSelectable(false);
    }

    if (order.leaser === "Leasecom") {
      setIsFleetCareSelectable(false);
      setErrorArray((oldArray) => [...oldArray, "The leaser can't be Leasecom"]);
    }
  }, [insuranceType]);

  return (
    <Layout direction="column" spacing={2} sx={{ padding: 4 }}>
      {
        error ? (
          <Alert severity="warning" onClose={() => setError(null)}>
            {error}
          </Alert>
        ) : null
      }
      <Heading variant="h3" color="secondary">Invoicing Details</Heading>
      <Layout direction="column" spacing={1}>
        <Text variant="body1" color="secondary">
          Order Information:
          {" "}
          {order.contract_number ? `Contract n° ${order.contract_number}` : "-"}
        </Text>
        <Text variant="body1" color="secondary">
          Invoicing date:
          {" "}
          {order.billing_date ? dateConverterToddmmyyyy(new Date(order.billing_date)) : "-"}
        </Text>
        <Text variant="body1" color="secondary">
          Invoice number:
          {" "}
          {order.bill_number ? order.bill_number : "-"}
        </Text>
      </Layout>
      <Separator direction="horizontal" />
      <Heading variant="h3" color="secondary">Insurance</Heading>
      <Text variant="body1" color="secondary">
        Insurance status:
        {" "}
        {insurance?.status || "-"}
      </Text>

      {insurance && <Link label="See Insurance Subscription in Stripe" href={`https://dashboard.stripe.com/subscription/${insurance?.stripe_id}`} />}
      <Layout direction="row" justifyContent="space-between">
        <RadioGroup value={insuranceType} onChange={handleInsuranceTypeChange} row>
          <Radio value="FLEET_INSURANCE" label="Fleet insurance" disabled={(!isEditable && insuranceType !== "FLEET_INSURANCE") || !isFleetCareSelectable} />
          <Radio value="PERSONAL_INSURANCE" label="Personal insurance" disabled={(!isEditable && insuranceType !== "PERSONAL_INSURANCE") || !hasPersonalInsuranceFileAccepted} />
          <Tooltip title="User needs to have an accepted document proving their personal insurance subscription" placement="top">
            <Icon icon={faInfoCircle} />
          </Tooltip>
          <Radio value="LEASER_INSURANCE" label="Leaser insurance" disabled={!isEditable && insuranceType !== "LEASER_INSURANCE"} />
        </RadioGroup>

        {insuranceType === "FLEET_INSURANCE"
          && (
            <Layout direction="column" alignItems="flex-end" spacing={1}>
              <Button
                variant="contained"
                color="error"
                label="Cancel Fleet Care insurance"
                onClick={cancelInsurance}
                disabled={mutation.cancelInsurance === MutationStatus.PENDING}
                loading={mutation.cancelInsurance === MutationStatus.PENDING}
              />
              <Text variant="caption">This action will cancel Stripe and Evy subscription</Text>
            </Layout>
          )}
      </Layout>

      {(!isFleetCareSelectable && isEditable) && (
        <Alert severity="warning" icon>
          This insurance’s contract can’t be modified due to the following reason :
          {errorArray.map((error) => (
            <Text variant="body2" key={error}>
              •
              {" "}
              {error}
            </Text>
          ))}

        </Alert>
      )}
      {insurancePrice && (
        <Text>
          Integrate these contracts into Fleet Care for:
          <strong>
            {" "}
            €
            {insurancePrice}
            /month excluding VAT.
            {" "}
          </strong>

        </Text>
      )}
      <Separator direction="horizontal" />
      <Heading variant="h3" color="secondary">Generate Files</Heading>
      {
        isAlreadyGenerated && (
          <Alert severity="warning">
            This invoice has already been generated
          </Alert>
        )
      }
      <Layout direction="row" spacing={2}>
        {countryWithInvoicing(order.company.country)
          && (
            <GenerateFiles fileType="BILL" order={order} setIsAlreadyGenerated={setIsAlreadyGenerated} setError={setError} reload={reload}>
              Generate Invoice
            </GenerateFiles>
          )}
        <GenerateFiles fileType="DELIVERY_FORM" order={order}>
          Generate Delivery form
        </GenerateFiles>
        <GenerateFiles fileType="ORDER_FORM" order={order}>
          Generate Order form
        </GenerateFiles>
        {insuranceType === "FLEET_INSURANCE" && (
          <GenerateFiles fileType="INSURANCE_FORM" order={order} setError={setError}>
            Generate Insurance form
          </GenerateFiles>
        )}
      </Layout>
      {
        orderStatusToDisplay.includes(order.status) && countryWithInvoicing(order.company.country)
        && (
          <Button
            variant="outlined"
            color="secondary"
            label="Send invoice to Pennylane"
            onClick={sendToPennylane}
            loading={mutation.sendToPennylane === MutationStatus.PENDING}
            disabled={mutation.sendToPennylane === MutationStatus.PENDING}
            endIcon={faFileCirclePlus}
          />
        )
      }
      <Layout direction="row" spacing={1} style={{ position: "fixed", bottom: "100px", right: "10px" }}>
        {isEditable
          ? (
            <>
              <Button
                variant="contained"
                color="primary"
                label="Save Changes"
                onClick={saveStatusChange}
                loading={mutation.changeInsuranceStatus === MutationStatus.PENDING}
                disabled={mutation.changeInsuranceStatus === MutationStatus.PENDING}
              />
              <Button
                variant="outlined"
                color="secondary"
                label="Cancel"
                onClick={cancelEdit}
              />
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              label="Edit"
              onClick={() => setIsEditable(true)}
            />
          )}
      </Layout>
    </Layout>
  );
};

export default OrderBilling;
