import React, { useContext, useEffect, useState } from "react";
import {
  Button, Card, Checkbox, Heading, Layout, Link, Text, TextField,
} from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";
import LoadingSpinner from "../../common/LoadingSpinner";
import ErrorNotification from "../../common/ErrorNotification";
import UploadZone from "../../blocks/UploadZone";

import CompanyContext from "../../../contexts/CompanyContext";

import CopyButton from "../../../tools/CopyButton";

import CompanyDiscountInfo from "./CompanyDiscountInfo";
import CompanyMdmInfo from "./CompanyMdmInfo";

const CompanyGeneralInfo = () => {
  const { user: adminUser } = useContext(UserContext);
  const [isEditable, setIsEditable] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  const { company, setCompany, reloadCompany } = useContext(CompanyContext);

  const defaultAddress = company.addresses ? company.addresses.find((a) => a.is_default) : undefined;

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === "siret" || name === "nif") {
      const formattedValue = value.replace(/\s/g, "");

      setCompany((prevInfo) => ({ ...prevInfo, [name]: formattedValue }));

      return;
    }
    setCompany((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const saveCompany = async () => {
    const siret_format = /^\d{14}$/;

    if (company.siret && !siret_format.test(company.siret)) {
      setErrors(["invalid SIRET : 14 digits"]);

      return;
    }

    const companyData = {
      name: company.name,
      legal_name: company.legal_name,
      siren: company.siren,
      bnp_number: company.bnp_number,
      img_url: company.img_url,
      siret: company.siret,
      nif: company.nif,
      hr: company.hr,
      crn: company.crn,
      country: company.country,
      tva_number: company.tva_number,
      has_discount: company.has_discount,
      abm_id: company.abm_id,
      num_employees: company.num_employees,
      eu_registration_number: company.eu_registration_number,
      mdm_employee_password: company.mdm_employee_password,
    };

    await adminUser.api.modifyCompany(company.id, companyData);
    await reloadCompany();

    setMessage("Company Info Updated");

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const removeLogo = async () => {
    await adminUser.api.modifyCompany(company.id, { img_url: "" });
    await reloadCompany();
  };

  const uploadImage = async (uploadZoneImage) => {
    if (company.id === "") {
      return;
    }
    const formData = new FormData();

    formData.append("company_image", uploadZoneImage[0]);

    await adminUser.api.modifyCompanyLogo(company.id, formData);
    await reloadCompany();
  };

  const handleCancel = () => {
    setIsEditable(false);
  };

  const handleSave = () => {
    saveCompany();
    setIsEditable(false);
  };

  const onCheckboxClick = () => {
    const customEvent = { target: { name: "has_discount", value: !company.has_discount } };

    onChange(customEvent);
  };

  const paymentMethodsData = async () => {
    const allPaymentMethod = await adminUser.api.getPaymentMethods(company.id);

    return allPaymentMethod.data.find((pm) => pm.is_active === true);
  };

  useEffect(() => {
    const fetchDefaultPayments = async () => {
      try {
        const paymentMethods = await paymentMethodsData();

        setPaymentMethod(paymentMethods.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDefaultPayments();
  }, [company]);

  if (!company) {
    return <LoadingSpinner />;
  }

  return (
    <Layout direction="column" spacing={8}>
      <Card spacing={4}>
        <Layout direction="column" spacing={4}>

          <Layout direction="row" spacing={4}>
            <Layout direction="column" spacing={2}>
              {company.img_url ? (
                <>
                  <figure style={{ maxWidth: "250px" }}>
                    <img loading="lazy" src={company.img_url} alt={company.name} />
                  </figure>
                  <div style={{ textAlign: "center", marginTop: "1em" }}>
                    <Button variant="text" color="primary" size="small" label="Delete Logo" onClick={removeLogo} />
                  </div>
                </>
              ) : (
                <div style={{ width: "100%" }}>
                  <UploadZone uploadFile={uploadImage}> company logo</UploadZone>
                </div>
              )}
              <div>
                <Heading variant="h4">Default Address</Heading>
                {defaultAddress ? (
                  <>
                    {defaultAddress.address1}
                    <br />
                    {defaultAddress.zip}
                    <br />
                    {defaultAddress.city}
                    <br />
                  </>
                )
                  : <Text variant="body1">Unknown</Text>}
              </div>
            </Layout>

            <Layout direction="column" spacing={2}>
              {message ? <div className="successMsg">{message}</div> : ""}
              {errors.length > 0 && <ErrorNotification errors={errors} clear={() => setErrors([])} />}
              <Layout direction="row" spacing={1}>
                <TextField
                  required
                  label="Name"
                  name="name"
                  value={company.name}
                  onChange={onChange}
                  disabled={!isEditable}
                />

                <TextField
                  required
                  name="legal_name"
                  label="Legal Name"
                  value={company.legal_name}
                  onChange={onChange}
                  disabled={!isEditable}
                />
              </Layout>

              <Layout direction="row" spacing={1}>
                <TextField
                  name="siren"
                  label={(
                    <>
                      SIREN
                      <CopyButton value={company.siren} />
                    </>
                  )}
                  value={company.siren || ""}
                  onChange={onChange}
                  disabled={!isEditable}
                />

                <TextField
                  name="siret"
                  label={(
                    <>
                      SIRET
                      <CopyButton value={company.siret} />
                    </>
                  )}
                  value={company.siret || ""}
                  onChange={onChange}
                  disabled={!isEditable}
                />

                <TextField
                  name="bnp_number"
                  label={(
                    <>
                      BNP Client number
                      <CopyButton value={company.bnp_number} />
                    </>
                  )}
                  value={company.bnp_number || ""}
                  onChange={onChange}
                  disabled={!isEditable}
                />
              </Layout>

              <Layout direction="row" spacing={1}>
                <TextField
                  name="num_employees"
                  label="Total employees"
                  value={company.num_employees || undefined}
                  onChange={onChange}
                  disabled={!isEditable}
                />

                <TextField
                  required
                  name="country"
                  label="Country"
                  value={company.country || undefined}
                  onChange={onChange}
                  disabled={!isEditable}
                />

                <TextField
                  name="nif"
                  label={(
                    <>
                      {" "}
                      NIF (Spain)
                      <CopyButton value={company.nif} />
                    </>
                  )}
                  value={company.nif || undefined}
                  onChange={onChange}
                  disabled={!isEditable}
                />
              </Layout>

              <Layout direction="row" spacing={1}>
                <TextField
                  name="tva_number"
                  label={(
                    <>
                      VAT Number
                      <CopyButton value={company.tva_number} />
                    </>
                  )}
                  value={company.tva_number || undefined}
                  onChange={onChange}
                  disabled={!isEditable}
                />

                <TextField
                  name="crn"
                  label={(
                    <>
                      Company Number (UK)
                      <CopyButton value={company.crn} />
                    </>
                  )}
                  value={company.crn || undefined}
                  onChange={onChange}
                  disabled={!isEditable}
                />

                <TextField
                  name="hr"
                  label={(
                    <>
                      HR (Germany)
                      <CopyButton value={company.hr} />
                    </>
                  )}
                  value={company.hr || undefined}
                  onChange={onChange}
                  disabled={!isEditable}
                />
              </Layout>
              <Layout direction="row" spacing={1}>
                <TextField
                  name="eu_registration_number"
                  label={(
                    <>
                      Registration Number (EU)
                      <CopyButton value={company.eu_registration_number} />
                    </>
                  )}
                  value={company.eu_registration_number || undefined}
                  onChange={onChange}
                  disabled={!isEditable}
                />
              </Layout>

              <Layout direction="column" spacing={1}>
                {paymentMethod && (
                  <>
                    <Text variant="body2">SEPA Mandates</Text>
                    <Layout direction="row" spacing={1} alignItems="flex-end">
                      <TextField disabled value={paymentMethod.is_active ? `${paymentMethod.name} *` : paymentMethod.name} />
                      <TextField disabled value={`**** **** **** **** **** ${paymentMethod.last4}`} />
                    </Layout>
                  </>
                )}
                {company?.stripe_id
                  && <Link label="View in Stripe" href={`https://dashboard.stripe.com/customers/${company.stripe_id}`} targetBlank />}
              </Layout>

              <Checkbox
                label="Has discount"
                name="has_discount"
                checked={company.has_discount}
                disabled={!isEditable}
                onChange={onCheckboxClick}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {isEditable ? (
                  <Layout direction="row" spacing={2}>
                    <Button variant="contained" color="primary" label="Save Changes" onClick={handleSave} />
                    <Button variant="outlined" color="secondary" label="Cancel" onClick={handleCancel} />
                  </Layout>
                ) : (
                  <Button variant="contained" color="primary" label="Edit" onClick={() => setIsEditable(true)} />
                )}
              </div>

            </Layout>

          </Layout>
          <br />
        </Layout>
      </Card>
      <CompanyMdmInfo onSave={saveCompany} />
      <CompanyDiscountInfo onSave={saveCompany} />
    </Layout>
  );
};

export default CompanyGeneralInfo;
