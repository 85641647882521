export const providers = [
  { label: "Agora", value: "Agora" },
  { label: "Amazon", value: "Amazon" },
  { label: "Apple Business", value: "Apple Business" },
  { label: "Apple Business UK", value: "Apple Business UK" },
  { label: "Apple Particulier", value: "Apple Particulier" },
  { label: "Apple Particulier UK", value: "Apple Particulier UK" },
  { label: "Asus", value: "Asus" },
  { label: "Bak2", value: "Bak2" },
  { label: "Boulanger", value: "Boulanger" },
  { label: "Banana Computer", value: "Banana Computer" },
  { label: "CDiscount", value: "CDiscount" },
  { label: "CDiscount Pro", value: "CDiscount Pro" },
  { label: "CRS", value: "CRS" },
  { label: "Dell Pro", value: "Dell Pro" },
  { label: "Dell Particulier", value: "Dell Particulier" },
  { label: "Devialet", value: "Devialet" },
  { label: "Energy Net", value: "Energy Net" },
  { label: "Eze", value: "Eze" },
  { label: "FactoRefurb", value: "FactoRefurb" },
  { label: "Flex IT", value: "Flex IT" },
  { label: "FNAC", value: "FNAC" },
  { label: "Foxway", value: "Foxway" },
  { label: "HP", value: "HP" },
  { label: "Ingram", value: "Ingram" },
  { label: "Inmac", value: "Inmac" },
  { label: "Intecat", value: "Intecat" },
  { label: "Itxchange global", value: "Itxchange global" },
  { label: "Kasigra", value: "Kasigra" },
  { label: "KRCS", value: "KRCS" },
  { label: "K-tuin", value: "K-tuin" },
  { label: "Laptops Direct", value: "Laptops Direct" },
  { label: "Largo", value: "Largo" },
  { label: "LDLC Pro", value: "LDLC Pro" },
  { label: "Leet", value: "Leet" },
  { label: "Lenovo", value: "Lenovo" },
  { label: "Logitech", value: "Logitech" },
  { label: "Work Louder", value: "Work Louder" },
  { label: "LM-ECO", value: "LM-ECO" },
  { label: "Ma Fabrik", value: "Ma Fabrik" },
  { label: "Macnificos", value: "Macnificos" },
  { label: "MC2IT", value: "MC2IT" },
  { label: "Media Markt", value: "Media Markt" },
  { label: "Merkia", value: "Merkia" },
  { label: "MGF", value: "MGF" },
  { label: "Microsoft", value: "Microsoft" },
  { label: "MNM", value: "MNM" },
  { label: "NEC", value: "NEC" },
  { label: "Nudos", value: "Nudos" },
  { label: "Persy", value: "Persy" },
  { label: "Sens-IT", value: "Sens-IT" },
  { label: "iPower Resale", value: "iPower Resale" },
  { label: "Pccomponentes", value: "Pccomponentes" },
  { label: "Remarketed", value: "Remarketed" },
  { label: "Rue du Commerce", value: "Rue du Commerce" },
  { label: "SCC", value: "SCC" },
  { label: "TD Synnex", value: "TD Synnex" },
  { label: "TopBiz", value: "TopBiz" },
  { label: "Tecspal", value: "Tecspal" },
  { label: "VDD", value: "VDD" },
  { label: "VSeven", value: "VSeven" },
  { label: "Worten", value: "Worten" },
  { label: "Work with Island", value: "Work with Island" },
  { label: "Other", value: "Other" },
  { label: "Empty", value: "" },
  { label: "Senetic", value: "Senetic" },
  { label: "Sony", value: "Sony" },
  { label: "Persy Booths", value: "Persy Booths" },
  { label: "Leet Design", value: "Leet Design" },
  { label: "Offcoustic", value: "Offcoustic" },
];
