import React from "react";
import { Layout } from "@fleet.co/tarmac";
import CImage from "./CImage";

const LoadingSpinner = () => (
  <Layout direction="column" alignItems="center" justifyContent="center" sx={{ width: "100vw", height: "100vh" }}>
    <CImage label="hourglass_loader_bc89ze" width={128} />
  </Layout>
);

export default LoadingSpinner;
