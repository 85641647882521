import React, { useContext, useState } from "react";
import {
  Button, Dialog, Select,
} from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";
import styles from "./ProductRow.module.scss";
import { productSpecs } from "../../../data/productSpecs";
import { formatMoney } from "../../../helpers/formatHelpers";
import { useToastContext } from "../../../contexts/ToastContext";

const ProductRow = (props) => {
  const {
    product, product_group, getProductGroups, openProductModal, currentCurrency,
  } = props;
  const { user: adminUser } = useContext(UserContext);
  const { addToast } = useToastContext();

  const [visibility, setVisibility] = useState(product.visibility);
  const [confirmVisibilityDialog, setConfirmVisibilityDialog] = useState(false);

  // Change visibility in backend
  const handleVisibilityChange = async () => {
    if (product.prices.length !== 2) {
      addToast("You must provide prices for available and unavailable products");
    } else {
      await adminUser.api.changeProductVisibility(product.id, visibility);
      await getProductGroups();
    }
  };

  const onVisibilitySelectChange = (value) => {
    setVisibility(value);
    setConfirmVisibilityDialog(true);
  };

  // Confirmation modal title changing when visibility is changed
  const modalTitle = (visibility) => {
    if (visibility === "AVAILABLE") {
      // If all products from pg are not available/archived/draft and the action is changing status
      // to Available, text changes to let user know that the pg will re-appear in shop and website
      if (props.availableProducts === 0) {
        return "Available Product and Group";
      }

      return "Available Product";
    }

    if (visibility === "UNAVAILABLE") {
      if (props.availableProducts === 1) {
        return "Unavailable Product and Group";
      }

      return "Unavailable Product";
    }

    if (visibility === "ARCHIVED") {
      if (props.availableProducts === 1) {
        return "Archived Product and Group";
      }

      return "Archived Product";
    }

    if (visibility === "DRAFT") {
      return "Draft Product";
    }

    return null;
  };

  const visibilities = [
    { value: "DRAFT", label: "Draft" },
    { value: "AVAILABLE", label: "Available" },
    { value: "UNAVAILABLE", label: "Unavailable" },
    { value: "ARCHIVED", label: "Archived" },
  ];

  const rowElementStyle = `${styles.productRow_info} ${product.visibility === "AVAILABLE" ? "" : styles.opaque}`;

  // ! End of  Shop & Website Visibility

  const editProduct = () => {
    openProductModal(product_group, product);
  };

  const getShippingRange = () => {
    if (product.min_shipping && product.max_shipping) {
      return (
        <>
          {product.min_shipping}
          {" "}
          À
          {product.max_shipping}
          {" "}
          JOURS
          {" "}
        </>
      );
    }

    return "-";
  };

  const ActionComponent = (
    <>
      <Button color="secondary" label="No" onClick={() => setConfirmVisibilityDialog(false)} />
      <Button color="warning" label="Yes" onClick={handleVisibilityChange} />
    </>
  );

  const getValuePrice = () => {
    const priceToDisplay = product.prices.find((price) => price.currency === currentCurrency);

    if (priceToDisplay) {
      return priceToDisplay.amount;
    }

    return null;
  };

  return (
    <>
      <Dialog
        onClose={() => setConfirmVisibilityDialog(false)}
        title={modalTitle(visibility)}
        Actions={ActionComponent}
        open={confirmVisibilityDialog}
      >
        <ModalText visibility={visibility} numAvailableProducts={props.availableProducts} />
      </Dialog>

      <div className={styles.productRow}>
        <div
          onClick={editProduct}
          className={`${styles.productRow_info} ${styles.price} ${product.visibility === "AVAILABLE" ? "" : styles.opaque}`}
        >
          {formatMoney(getValuePrice(), currentCurrency)}
        </div>

        {productSpecs.filter((spec) => spec.categories.includes(props.product_group.category) && spec.name !== "keyboard_layout").map((spec) => (
          <div onClick={editProduct} className={rowElementStyle} key={spec.name}>
            {spec.options(props.product_group.category).find((o) => o.value === props.product[spec.name])?.label}
          </div>
        ))}

        <div onClick={editProduct} className={rowElementStyle}>
          {getShippingRange()}
        </div>

        <Select
          value={product.visibility}
          options={visibilities}
          onChange={onVisibilitySelectChange}
        />
      </div>
    </>
  );
};

// Confirmation modal text changing when visibility is changed
const ModalText = ({ visibility, numAvailableProducts }) => {
  if (visibility === "AVAILABLE") {
    // If all products from pg are not available/archived/draft and the action is changing status
    // to Available, text changes to let user know that the pg will re-appear in shop and website
    if (numAvailableProducts === 0) {
      return (
        <div>
          Are you sure
          {" "}
          <span className={styles.green}>this product is available for purchase?</span>
          {" "}
          This product will
          be visible on the shop and on the website as well as its product group.
        </div>
      );
    }

    return (
      <div>
        Are you sure
        {" "}
        <span className={styles.green}>this product is available for purchase?</span>
        {" "}
        This product will
        be visible on the shop and on the website.
      </div>
    );
  }

  if (visibility === "UNAVAILABLE") {
    // If only one product left is available and the action is changing status
    // to unavailable/archived, text changes to let user know that the pg will disappear in shop and website
    if (numAvailableProducts === 1) {
      return (
        <>
          Are you sure
          {" "}
          <span className={styles.green}>this product is not available for purchase?</span>
          This product won’t be visible on the shop and on the website as well as its product group.
        </>
      );
    }

    return (
      <>
        Are you sure
        {" "}
        <span className={styles.green}>this product is currently unavailable for purchase?</span>
        {" "}
        This
        product will be visible as “available soon” on the shop and on the website.
      </>
    );
  }

  if (visibility === "ARCHIVED") {
    // If only one product left is available and the action is changing status
    // to unavailable/archived, text changes to let user know that the pg will disappear in shop and website
    if (numAvailableProducts === 1) {
      return (
        <>
          Are you sure
          {" "}
          <span className={styles.green}>this product is no longer available for purchase?</span>
          {" "}
          This
          product won’t be visible on the shop and on the website as well as its product group.
        </>
      );
    }

    return (
      <>
        Are you sure
        {" "}
        <span className={styles.green}>this product is no longer available for purchase?</span>
        {" "}
        This
        product won’t be visible on the shop and on the website.
      </>
    );
  }

  if (visibility === "DRAFT") {
    return (
      <>
        Are you sure
        {" "}
        <span className={styles.green}>this product is only a draft?</span>
        {" "}
        This product won’t be visible
        on the shop and on the website.
      </>
    );
  }

  return null;
};

export default ProductRow;
