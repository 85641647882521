import React, {
  useCallback, useContext, useEffect, useState,
} from "react";
import {
  Button, Card, Dialog, Heading, Layout, Text,
} from "@fleet.co/tarmac";
import UserContext from "../../../tools/UserContext";
import CompanyContext from "../../../contexts/CompanyContext";

import styles from "./CompanyDiscount.module.scss";

import DiscountTableRow from "./DiscountTable/DiscountTableRow";
import DiscountTableMdmRow from "./DiscountTable/DiscountTableMdmRow";
import { useToastContext } from "../../../contexts/ToastContext";

const productDiscountOptions = [
  { level: 1, min: "0", max: "19.90" },
  { level: 2, min: "19.91", max: "49.90" },
  { level: 3, min: "49.91", max: "79.90" },
  { level: 4, min: "79.91", max: "99.90" },
  { level: 5, min: "99.91", max: null },
];

const mdmDiscountOptions = [
  { title: "Free trial", sub: "default: 1 month", type: "number" },
  { title: "Price/month/device", sub: "default: 6.90€/£" },
];

const CompanyDiscountInfo = () => {
  const { company, reloadCompany } = useContext(CompanyContext);
  const { user: adminUser } = useContext(UserContext);
  const [isEditable, setIsEditable] = useState(false);
  const [mdmIsEditable, setMdmIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [discounts, setDiscounts] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mdmChangeDiscount, setMdmChangeDiscount] = useState(
    {
      mdm_trial_offer: null,
      mdm_unit_amount: null,
    },
  );
  const { addToast } = useToastContext();

  useEffect(() => {
    if (company) {
      setMdmChangeDiscount({
        mdm_trial_offer: company.mdm_trial_offer,
        mdm_unit_amount: company.mdm_unit_amount,
      });
    }
  }, [company]);

  const handleChange = (level, discountInfo) => {
    const chosenLevelInfos = productDiscountOptions.find((pdo) => pdo.level === level);
    const updatingDiscount = discounts.find((d) => d.min_price === chosenLevelInfos.min);

    if (updatingDiscount) {
      const updatedDiscounts = discounts.map((d) => {
        if (d.min_price === updatingDiscount.min_price) {
          return {
            ...d,
            offer_type: discountInfo.offer_type,
            offer_value: discountInfo.offer_value,
          };
        }

        return d;
      });

      setDiscounts(updatedDiscounts);
    } else {
      const newDiscount = {
        min_price: chosenLevelInfos.min,
        max_price: chosenLevelInfos.max,
        offer_type: discountInfo.offer_type,
        offer_value: discountInfo.offer_value,
      };

      setDiscounts([...discounts, newDiscount]);
    }
  };

  const handleEdit = useCallback(() => {
    if (isEditable) {
      // deep copy because we copy array of objects that is a key value of an object
      const newDiscounts = company.product_discounts.map((d) => ({ ...d }));

      setDiscounts(newDiscounts);
    }

    setIsEditable(!isEditable);
  }, [isEditable]);

  const onSave = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true);

      const discountsToCreate = discounts.filter((discount) => !discount.id);
      const discountsToModify = discounts.filter((discount) => company.product_discounts.find((d) => {
        const hasSameId = d.id === discount.id;
        const hasNotSameOfferType = d.offer_type !== discount.offer_type;
        const hasNotSameOfferValue = d.offer_value !== discount.offer_value;

        return hasSameId && (hasNotSameOfferType || hasNotSameOfferValue);
      }));

      for (const discount of discountsToModify) {
        await adminUser.api.modifyCompanyDiscount(company.id, discount);
      }

      for (const discount of discountsToCreate) {
        await adminUser.api.addCompanyDiscount(company.id, discount);
      }
    }
    setIsEditable(false);
    await reloadCompany();
    setIsLoading(false);
    setIsModalOpen(false);
  }, [adminUser.api, company.id, discounts, isLoading]);

  const mdmOnSave = async () => {
    if (mdmChangeDiscount.mdm_unit_amount < 490 || mdmChangeDiscount.mdm_unit_amount > 1290) {
      addToast("The price can only be between: 4.90 and 12.90€/£(HT)");

      return;
    }

    setIsLoading(true);
    await adminUser.api.modifyCompany(company.id, mdmChangeDiscount);
    setMdmIsEditable(false);
    await reloadCompany();
    setIsLoading(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    // deep copy because we copy array of objects that is a key value of an object
    const newDiscounts = company.product_discounts.map((d) => ({ ...d }));

    setDiscounts(newDiscounts);
  }, [company]);

  const ActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={() => setIsModalOpen(false)} />
      <Button color="primary" label={isLoading ? "Loading" : "Save"} onClick={mdmIsEditable ? mdmOnSave : onSave} />
    </>
  );

  const discountCurrency = company?.country === "United Kingdom" ? "GBP" : "EUR";

  const numberDevicesMdm = company.devices.filter((d) => d.device_mdm && d.device_mdm?.status !== "DRAFT").length;

  return (
    <Card spacing={4}>
      <Layout direction="column" spacing={4}>
        <Heading variant="h3" color="info">
          Discount
        </Heading>
        <Layout direction="row" justifyContent="space-around" mobileDirection="column">
          <Layout direction="column" spacing={2} style={{ maxWidth: "600px" }} fullWidth>
            <Layout
              direction="row"
              fullWidth
              justifyContent="space-around"
              alignItems="center"
            >
              <Text className={styles.cellHeader}>
                Levels
              </Text>
              <Text variant="body1">
                Discount
              </Text>
              <Text variant="body1">
                Last modifications
              </Text>
            </Layout>

            <Layout direction="column">
              {productDiscountOptions.map((productDiscountInfo, index) => (
                <DiscountTableRow
                  key={index}
                  discountInfos={productDiscountInfo}
                  discount={discounts?.find((d) => d.min_price === productDiscountInfo.min)}
                  isEditable={isEditable}
                  handleChange={handleChange}
                  discountCurrency={discountCurrency}
                />
              ))}
            </Layout>

            <Layout direction="row" className={styles.editButton} justifyContent="space-between">
              <Button onClick={handleEdit} variant="contained" color={isEditable ? "secondary" : "primary"} label={isEditable ? "Cancel" : "Edit"} />
              {isEditable && <Button onClick={() => setIsModalOpen(true)} variant="contained" color="primary" label="Save" />}
            </Layout>
          </Layout>

          <Layout direction="column" spacing={2} style={{ maxWidth: "600px" }} fullWidth>
            <Layout direction="row" justifyContent="space-between">
              <Heading variant="h4" color="info">
                MDM Discount (
                {numberDevicesMdm}
                )
              </Heading>
              <Layout direction="column">
                <Text variant="body2" bold>No subscription</Text>
                <Text variant="body2">Price can be between: 4.90 and 12.90€/£(HT)</Text>
              </Layout>
            </Layout>
            <Layout
              direction="row"
              fullWidth
              justifyContent="space-between"
              alignItems="center"
            >
              <Text className={styles.cellHeader}>
                Discount type
              </Text>
              <Text variant="body1">
                Discount
              </Text>
            </Layout>

            <Layout direction="column">
              {mdmDiscountOptions.map((mdmDiscountInfo) => (
                <DiscountTableMdmRow
                  discountInfos={mdmDiscountInfo}
                  isEditable={mdmIsEditable}
                  mdmChangeDiscount={mdmChangeDiscount}
                  setMdmChangeDiscount={setMdmChangeDiscount}
                />
              ))}
            </Layout>

            <Layout direction="row" className={styles.editButton} justifyContent="space-between">
              <Button onClick={() => setMdmIsEditable(!mdmIsEditable)} variant="contained" color={mdmIsEditable ? "secondary" : "primary"} label={mdmIsEditable ? "Cancel" : "Edit"} />
              {mdmIsEditable && <Button onClick={() => setIsModalOpen(true)} variant="contained" color="primary" label="Save" />}
            </Layout>
          </Layout>
        </Layout>
        <Dialog
          title={mdmIsEditable ? "MDM Discount modifications" : "Discount modifications"}
          onClose={() => setIsModalOpen(false)}
          Actions={ActionComponent}
          open={isModalOpen}
        >
          <Text variant="body1">The previous given discounts will be modified and deleted after pressing save.</Text>
        </Dialog>
      </Layout>
    </Card>
  );
};

export default CompanyDiscountInfo;
