export const formatMoney = (amount, currency) => {
  if (!amount) return "-";
  const sum = parseFloat(amount);
  const price = sum.toLocaleString(
    currency === "EUR" ? "fr" : "en",
    {
      style: "currency",
      currency,
      narrowSymbol: true,
    },
  );

  return price;
};
